<!--
 * @Author: LiuXin
 * @Date: 2021-09-08 15:41:46
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-21 14:01:09
-->
<template>
  <div class="over_view_wrapper w100 bcg_w home_card">
    <template v-for="c in children" :key="c">
      <component :is="c.component" :echarts-options="c.echartsOptions"></component>
    </template>
  </div>
</template>

<script>
import OperatorOverView from './components/OperatorOverView.vue';
import DesignerOverView from './components/DesignerOverView.vue';

export default {
  name: 'HomeOverViewWrapper',
  components: { OperatorOverView, DesignerOverView },
  props: {
    children: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
:deep(.ant-spin-nested-loading) {
  height: calc(100% - 40px);
  .ant-spin-container {
    height: 100%;
    width: 100%;
  }
}
</style>
<style lang="less">
.over_view_wrapper {
  flex-direction: column;
  .module_title {
    a {
      font-size: 12px;
      color: #999999;
    }
  }
  .over_view {
    display: flex;
    height: calc(100% - 40px);
    .over_view_item {
      box-sizing: border-box;
      position: relative;
      display: flex;
      align-items: center;
      &:not(:last-child)::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 92px;
        background: #e9ecf7;
        position: absolute;
        right: 0;
      }
    }
  }
  .text_description {
    height: 90px;
    flex-grow: 1;
    .status_icon {
      .anticon-caret-up {
        color: rgba(19, 202, 92, 1);
      }
      .anticon-caret-down {
        color: rgba(215, 12, 12, 1);
      }
    }
    .text1 {
      font-size: 14px;
      color: #666666;
    }
    .text2 {
      font-size: 30px;
      font-weight: bold;
    }
    .text3 {
      font-size: 12px;
      color: #666666;
    }
    .text4 {
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      font-size: 24px;
    }
  }
}
</style>
