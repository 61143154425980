/* eslint-disable vue/no-side-effects-in-computed-properties */
<!--
 * @Author: LiuXin
 * @Date: 2021-09-08 16:09:20
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-21 17:04:15
-->
<template>
  <div class="w100 h100 consume_table home_card">
    <!-- 模块顶部 -->
    <p class="module_title">
      <span class="title_content">
        <span> {{ titleObj[filters.type] }} </span>
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent> <DownOutlined /> </a>
          <template #overlay>
            <a-menu @click="({ key }) => (filters.type = key)">
              <a-menu-item v-for="item in titleSelectOptions" :key="item.value">
                <a href="javascript:;">{{ item.label }}</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </span>
      <span class="select_group">
        <a-range-picker v-model:value="dateRange1" value-format="YYYY-MM-DD" allow-clear />
        <a-checkbox v-model:checked="restrict" @change="restrictChange">限制消耗素材上传时间</a-checkbox>
        <a-range-picker v-if="restrict" v-model:value="dateRange2" value-format="YYYY-MM-DD" :allow-clear="!restrict" />
      </span>
    </p>
    <div class="table_wrapper w100" ref="tableWrapper">
      <a-table size="small" v-bind="table">
        <template v-for="column in tableColumns" :key="column.dataIndex">
          <!-- 维度和人员名称 -->
          <a-table-column :title="column.title" :data-index="column.dataIndex" v-if="['name', 'post'].includes(column.dataIndex)">
            <template #default="{ text }">
              {{ text }}
            </template>
          </a-table-column>
          <!-- 其余进度条项 （只有勾选限制上传时间才显示上传数）-->
          <a-table-column :title="column.title" :data-index="column.dataIndex" v-else-if="showUploadNum(column.dataIndex)">
            <template #default="{ text }">
              <div>
                <a-progress :percent="text.percent1" v-bind="progressProps(1, column.dataIndex, text.value1)"> </a-progress>
              </div>
              <div>
                <a-progress :percent="text.percent2" v-bind="progressProps(2, column.dataIndex, text.value2)"> </a-progress>
              </div>
            </template>
          </a-table-column>
        </template>
      </a-table>
      <div class="load_more_wrapper" v-show="false">
        <div ref="loadMore" class="load_more" v-show="!table.loading && table.dataSource.length">
          <span v-if="!table.loadOver"> 正在加载 <LoadingOutlined /></span>
          <span v-else-if="showNoMore"> 没有更多了</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DownOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { getConsumeTableData } from '@/api/dashboard/designPerson.js';
import moment from 'moment';
import { LoadMore } from '@/utils/LoadMore.js';
import { materialType } from '@/config/globalData.js';

export default {
  name: 'DesignerTable',
  components: { DownOutlined, LoadingOutlined },
  inject: ['tableColumn'],
  data() {
    return {
      restrict: false, //当restrict为true时，resStartDate与resEndDate为必填
      tableColumns: [],
      loadEx: null, //滚动监听示例
      showNoMore: false, // 显示  ‘没有更多了’ 提示
      table: {
        dataSource: [],
        rowKey: 'key',
        scroll: { y: 230 },
        pagination: false,
        loading: false,
        loadOver: false,
        allPages: 1,
        page: 1, //不能将page放入filters，因为更改筛选项时需要将page设为1，会触发两次watcher
      },
      filters: {
        type: '4',
        limit: 10,
        resStartDate: '',
        resEndDate: '',
        startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      },
      titleSelectOptions: [
        { label: '按内部外部', value: '1' },
        { label: '按素材来源', value: '2' },
        { label: '按图片or视频', value: '3' },
        { label: '按设计人员', value: '4' },
        { label: '按关联产品', value: '5' },
      ],
    };
  },
  computed: {
    // 时间范围1
    dateRange1: {
      get() {
        return [this.filters.startDate, this.filters.endDate];
      },
      set(val) {
        this.filters.startDate = val[0];
        this.filters.endDate = val[1];
      },
    },
    // 时间范围2
    dateRange2: {
      get() {
        return [this.filters.resStartDate, this.filters.resEndDate];
      },
      set(val) {
        this.filters.resStartDate = val[0];
        this.filters.resEndDate = val[1];
      },
    },
    // 标题映射字典
    titleObj() {
      const obj = {};
      this.titleSelectOptions.forEach(item => {
        obj[item.value] = item.label;
      });
      return obj;
    },
    // 表格进度条配置
    progressProps() {
      const props = {
        style: { width: '120px' },
        size: 'small',
      };
      return (type, slot, num) => {
        if (['costGt1000MaterialRate', 'clickRate'].includes(slot)) {
          props.format = () => Number((num * 100).toFixed(2)) + '%'; //format这里必须使用函数
        } else {
          props.format = () => num; //format这里必须使用函数
        }
        props.strokeColor = type == 1 ? '#3E8AE9' : '#35C8EE';
        return props;
      };
    },
    // 显示上传数(只有勾选限制上传时间才显示上传数)
    showUploadNum() {
      return dataINdex => {
        if (dataINdex == 'uploadNum') {
          return this.restrict && !this.table.loading;
        } else {
          return true;
        }
      };
    },
  },
  watch: {
    filters: {
      async handler() {
        this.table.dataSource = [];
        this.table.loadOver = false;
        this.table.page = 1; //修改筛选条件时当前页设置为1
        this.loadEx.handleObserve();

        const tableData = await this.getTableData();
        this.table.dataSource = tableData;
        this.getTableColumns();
      },
      deep: true,
    },
    // '没有更多了'提示字样只显示三秒
    'table.loadOver'(val) {
      if (val && this.table.allPages > 1) {
        this.showNoMore = true;
        setTimeout(() => {
          this.showNoMore = false;
        }, 3000);
      }
    },
  },
  async created() {
    this.getTableColumns();
    const tableData = await this.getTableData();
    this.table.dataSource = tableData;
  },
  mounted() {
    this.initObserve();
  },
  methods: {
    // 获取表格数据
    async getTableData() {
      if (this.restrict && !this.filters.resStartDate) {
        this.$message.warning('请选择限制时间！');
        return;
      }
      this.table.loading = true;
      const postData = { ...this.filters, page: this.table.page };
      const res = await getConsumeTableData(postData);
      const originData = res.data ? res.data.records : [];
      const tableData = [];
      this.table.loading = false;
      const nameDic = materialType;
      if (originData.length) {
        originData.forEach(item => {
          const newItem = { name: nameDic[item.name] || item.name, post: nameDic[item.post] || item.post };
          this.tableColumn.designerConsume.forEach(column => {
            let key = column.dataIndex;
            newItem[key] = {
              value1: item.info?.[key],
              value2: item.contrast?.[key] || 0,
            };
            newItem[key].percent1 = Number(((newItem[key].value1 / newItem[key].value2 || 0) * 100).toFixed(2));
            newItem[key].percent2 = Number(((newItem[key].value2 / newItem[key].value1 || 0) * 100).toFixed(2));
            newItem.key = this.$UUID.v1();
          });
          tableData.push(newItem);
        });
      }
      // this.table.dataSource = tableData;
      this.table.allPages = res.data?.totalPage || 1;
      this.table.loading = false;
      return tableData;
    },
    // 获取表格表头
    getTableColumns() {
      const originColumns = this.tableColumn.designerConsume;
      const elseColumns = [
        { dataIndex: 'name', title: '内部外部' },
        { dataIndex: 'post', title: '岗位' },
        { dataIndex: 'name', title: '人员' },
        { dataIndex: 'name', title: '产品' },
        { dataIndex: 'name', title: '维度' },
      ];
      let columns = [];
      switch (Number(this.filters.type)) {
        case 1: //内部外部
          columns = [...[{ dataIndex: 'name', title: '内部外部' }], ...originColumns];
          break;
        case 2: //按素材类型
          columns = [elseColumns[4], ...originColumns];
          break;
        case 3: //按图片or视频
          columns = [elseColumns[4], ...originColumns];
          break;
        case 4: //按设计人员
          columns = [elseColumns[1], elseColumns[2], ...originColumns];
          break;
        case 5: //按素材关联产品
          columns = [elseColumns[3], ...originColumns];
          break;
      }
      this.tableColumns = columns;
      return columns;
    },
    // 限制条件改变时
    restrictChange(e) {
      if (!e.target.checked) {
        this.dateRange2 = ['', ''];
      } else {
        this.dateRange2 = this.dateRange1;
      }
    },
    // 滚动加载
    async getTableDataByScroll() {
      if (this.table.page == this.table.allPages) {
        this.table.loadOver = true;
        return;
      }
      this.table.page += 1;
      const tableData = await this.getTableData();
      this.table.dataSource.splice(this.table.dataSource.length, ...tableData);
    },
    // 初始化滚动监听
    initObserve() {
      this.table.loadOver = false;
      const tableWrapper = this.$refs.tableWrapper;
      const rootNode = tableWrapper.getElementsByClassName('ant-table-body')[0];
      rootNode.appendChild(this.$refs.loadMore);
      let loadEx = new LoadMore({
        root: rootNode,
        obs: this.$refs.loadMore,
        loadMoreFn: () => {
          if (this.table.loadOver) {
            loadEx.cancelObserve();
          } else {
            setTimeout(() => {
              this.getTableDataByScroll();
            }, 300);
          }
        },
      });
      this.loadEx = loadEx;
    },
  },
};
</script>

<style scoped lang="less">
.consume_table {
  display: flex;
  flex-direction: column;
  .select_group {
    & > * {
      margin-right: 16px;
    }
    .ant-calendar-picker {
      width: 180px;
    }
  }
  .table_wrapper {
    padding-top: 0;
    :deep(.ant-progress-text) {
      color: #666;
    }
    .load_more {
      width: 100%;
      line-height: 40px;
      border-bottom: 1px solid rgb(240, 240, 240);
      text-align: center;
    }
  }
}
</style>
