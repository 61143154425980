/*
 * @Author: LiuXin
 * @Date: 2021-09-16 15:01:56
 * @LastEditors: chenxing
 * @LastEditTime: 2021-11-04 12:26:46
 */
import axios from '@/utils/axios';
import store from '@/store/index';
//设计人员获取实时预览数据（第一部分）
export function getOverview(param) {
  let url = '/overview/information';
  url = getUrl(url, param);
  return axios.get(url);
}

//设计人员获取消耗趋势图表数据(第二部分)
export function getDataByType(param) {
  //  按素材内部外部 type
  //  按素材类型 【情景剧剪辑啥的】 make_type
  //  按图片or视频 【图片视频】 material_type
  //  按设计人员 producer
  //  按素材关联产品 project
  //  素材汇总 info

  let url = `/consumption/trend/material/${param.type}`;
  return axios.get(getUrl(url, param));
}

//设计人员获取消耗图表数据（第三部分）
export function getConsumeData(param) {
  let url = '';
  switch (param.type) {
    case 'putState': //近30天上传素材投放状态
      url = '/consumption/trend/material/put/state';
      return axios.get(getUrl(url, param));
    case 'typeCost': //近3日素材类型及产品消耗分布
      url = '/consumption/trend/material/type/cost';
      return axios.get(getUrl(url, param));
    case 'yestDayMake': //昨日脚本
      url = '/consumption/trend/material/yestDay/make';
      return axios.get(getUrl(url, param));
  }
}

//设计人员获取消耗表格数据（第四部分）
export function getConsumeTableData(param) {
  let url = '/designer/consumption/trend';
  url = getUrl(url, param);
  return axios.get(url);
}

// 拼接get请求
function getUrl(url, params) {
  params.job = store.state.globalEvent.args.job;
  let str = '';
  Object.keys(params).forEach(key => {
    if (params[key]) {
      str += `&${key}=${params[key]}`;
    }
  });
  return url + str.replace('&', '?');
}
