/*
 * @Author: LiuXin
 * @Date: 2021-09-07 16:04:34
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-25 16:56:19
 */
import echartsOptions from './echartOptions.js';
import { getDetailReport } from '@/api/home/detail.js';
import moment from 'moment';
const yestoday = moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD');
const layout = {
  // -----------------------------------------------------运营组员---------------------------------------------------------
  OPERATIONMEMBER: [
    // 运营部门组长（组员）
    {
      sortIndex: 1,
      component: 'yybm',
    },
    // // 实时概览
    // {
    //   sortIndex: 1,
    //   component: 'HomeOverViewWrapper',
    //   style: { height: '172px' },
    //   children: [{ sortIndex: 2, component: 'OperatorOverView', echartsOptions: echartsOptions.overview.consumeRate }],
    // },
    // // 消耗趋势
    // {
    //   sortIndex: 3,
    //   component: 'HomeChartWrapper',
    //   style: { height: '384px' },
    //   children: [
    //     {
    //       sortIndex: 1,
    //       showToDetail: true,
    //       echartsOptions: echartsOptions.trendChart.trend1,
    //       component: 'HomeChartWithTitle',
    //       getData: 'operatorGetTrendData',
    //       filters: { title: 'product', value1: '近七日' },
    //       titleSelectOptions: [
    //         { label: '汇总', value: 'total' },
    //         { label: 'TOP10产品', value: 'product' },
    //         { label: 'TOP10运营', value: 'operator' },
    //       ],
    //       selectOptions1: [
    //         { label: '近七日', value: '近七日' },
    //         { label: '近三十日', value: '近三十日' },
    //         { label: '本周', value: '本周' },
    //         { label: '本月', value: '本月' },
    //       ],
    //     },
    //     {
    //       sortIndex: 2,
    //       showToDetail: true,
    //       echartsOptions: echartsOptions.trendChart.trend1,
    //       component: 'HomeChartWithTitle',
    //       getData: 'operatorGetTrendData',
    //       filters: { title: 'operator', value1: '近七日' },
    //       titleSelectOptions: [
    //         { label: '汇总', value: 'total' },
    //         { label: 'TOP10产品', value: 'product' },
    //         { label: 'TOP10运营', value: 'operator' },
    //       ],
    //       selectOptions1: [
    //         { label: '近七日', value: '近七日' },
    //         { label: '近三十日', value: '近三十日' },
    //         { label: '本周', value: '本周' },
    //         { label: '本月', value: '本月' },
    //       ],
    //     },
    //   ],
    // },
    // // 消耗对比
    // {
    //   sortIndex: 2,
    //   component: 'HomeChartWrapper',
    //   style: { height: '336px' },

    //   children: [
    //     {
    //       sortIndex: 1,
    //       echartsOptions: echartsOptions.consumeCompare,
    //       component: 'HomeChartWithTitle',
    //       getData: 'operatorGetConsumeData',
    //       filters: { title: 'compare3' },
    //       titleSelectOptions: [
    //         { label: '产品余额与产品消耗比', value: 'compare1' },
    //         { label: '今日在投产品账户分布', value: 'compare2', showTotal: true },
    //         { label: '今日产品消耗', value: 'compare3' },
    //       ],
    //     },
    //     {
    //       sortIndex: 2,
    //       echartsOptions: echartsOptions.consumeCompare,
    //       component: 'HomeChartWithTitle',
    //       getData: 'operatorGetConsumeData',
    //       filters: { title: 'compare1' },
    //       titleSelectOptions: [
    //         { label: '产品余额与产品消耗比', value: 'compare1' },
    //         { label: '今日在投产品账户分布', value: 'compare2', showTotal: true },
    //         { label: '今日产品消耗', value: 'compare3' },
    //       ],
    //     },
    //   ],
    // },
    // // 消耗表格
    // {
    //   sortIndex: 4,
    //   component: 'HomeTableWrapper',
    //   style: { height: '318px' },
    //   children: [
    //     { sortIndex: 1, component: 'OperatorTable', defaultType: 'product' },
    //     { sortIndex: 2, component: 'OperatorTable', defaultType: 'account' },
    //   ],
    // },
  ],
  // -----------------------------------------------------运营组长了解详情---------------------------------------------------------
  OPERATIONLEADERDETAIL: [
    // 消耗趋势
    {
      sortIndex: 1,
      component: 'HomeDetailChart',
      style: { height: '384px' },
      children: [
        {
          sortIndex: 1,
          component: 'DetailChart',
          echartsOptions: echartsOptions.trendDetailChart.trend1,
          chartName: '',
          moduleTitle: '对比趋势图',
          selectOptions1: [],
          selectOptions2: [],
          hideToDetail: true,
          getTrendData: '',
        },
      ],
    }, // 消耗表格
    {
      sortIndex: 2,
      component: 'HomeDetailTables',
      style: { height: '484px' },
      children: [{ sortIndex: 1, component: 'DetailTable', chartName: '', moduleTitle: '多维度报表', getTableData: getDetailReport }],
    },
  ],
  // -----------------------------------------------------运营组员---------------------------------------------------------
  DESIGNER: [
    // 设计部门组长（组员）
    {
      sortIndex: 1,
      component: 'sjbm',
    },
    // // 实时预览
    // {
    //   sortIndex: 1,
    //   component: 'HomeOverViewWrapper',
    //   style: { height: '172px' },
    //   children: [{ sortIndex: 1, component: 'DesignerOverView' }],
    // },
    // // 消耗趋势
    // {
    //   sortIndex: 2,
    //   component: 'HomeChartWrapper',
    //   style: { height: '384px' },
    //   children: [
    //     {
    //       component: 'HomeChartWithTitle',
    //       sortIndex: 1,
    //       moduleTitle: '消耗趋势',
    //       hideToDetail: true,
    //       echartsOptions: echartsOptions.trendChart.trend1,
    //       getData: 'desinGetTrendData',
    //       filters: {
    //         title: 'producer',
    //         dateRange: [
    //           moment(yestoday)
    //             .subtract(6, 'days')
    //             .format('YYYY-MM-DD'),
    //           yestoday,
    //         ],
    //       },
    //       titleSelectOptions: [
    //         { label: '按素材内部外部', value: 'type' },
    //         { label: '按素材类型', value: 'make_type' },
    //         { label: '按图片or视频', value: 'material_type' },
    //         { label: '按设计人员', value: 'producer' },
    //         { label: '按素材关联产品', value: 'project' },
    //         { label: '素材汇总', value: 'info' },
    //       ],
    //     },
    //     {
    //       component: 'HomeChartWithTitle',
    //       sortIndex: 2,
    //       hideToDetail: true,
    //       moduleTitle: '消耗趋势',
    //       echartsOptions: echartsOptions.trendChart.trend1,
    //       getData: 'desinGetTrendData',
    //       filters: {
    //         title: 'project',
    //         dateRange: [
    //           moment(yestoday)
    //             .subtract(6, 'days')
    //             .format('YYYY-MM-DD'),
    //           yestoday,
    //         ],
    //       },
    //       titleSelectOptions: [
    //         { label: '按素材内部外部', value: 'type' },
    //         { label: '按素材类型', value: 'make_type' },
    //         { label: '按图片or视频', value: 'material_type' },
    //         { label: '按设计人员', value: 'producer' },
    //         { label: '按素材关联产品', value: 'project' },
    //         { label: '素材汇总', value: 'info' },
    //       ],
    //     },
    //   ],
    // },
    // // 消耗对比
    // {
    //   sortIndex: 3,
    //   component: 'HomeChartWrapper',
    //   style: { height: '452px' },
    //   children: [
    //     {
    //       component: 'HomeChartWithTitle',
    //       sortIndex: 1,
    //       hideToDetail: true,
    //       echartsOptions: echartsOptions.consumeCompare,
    //       getData: 'desinGetConsumeData',
    //       selectOptions1: [],
    //       placeholder1: '请选择部门',
    //       filters: { title: 'yestDayMake' },
    //       titleSelectOptions: [
    //         { label: '本月上传素材的投放状态', value: 'putState' },
    //         { label: '昨日素材来源及产品消耗分布', value: 'typeCost' },
    //         { label: '昨日脚本', value: 'yestDayMake' },
    //       ],
    //     },
    //     {
    //       component: 'HomeChartWithTitle',
    //       sortIndex: 2,
    //       hideToDetail: true,
    //       echartsOptions: echartsOptions.consumeCompare,
    //       getData: 'desinGetConsumeData',
    //       selectOptions1: [],
    //       placeholder1: '请选择部门',
    //       filters: { title: 'typeCost', value1: '' },
    //       titleSelectOptions: [
    //         { label: '本月上传素材的投放状态', value: 'putState' },
    //         { label: '昨日素材来源及产品消耗分布', value: 'typeCost' },
    //         { label: '昨日脚本', value: 'yestDayMake' },
    //       ],
    //     },
    //   ],
    // },
    // // 消耗表格
    // {
    //   sortIndex: 4,
    //   component: 'HomeTableWrapper',
    //   style: { height: '318px' },
    //   children: [{ sortIndex: 1, component: 'DesignerTable' }],
    // },
  ],
};
function getLayout(role) {
  let type = '';
  switch (role) {
    case 'OPERATION': //运营人员
      type = 'OPERATIONMEMBER';
      break;
    case 'OPERATIONLEADERDETAIL': //运营组长详情页布局
      type = 'OPERATIONLEADERDETAIL';
      break;
    case 'SCENARIST': //("编导"),
    case 'FILEMAKER': //("制片"),
    case 'PHOTOGRAPHER': //("拍摄"),
    case 'POSTER': //("后期"),
      if (role == 'POSTER') {
        // layout.DESIGNER[2].children[0].filters.title = 'putState';
      }
      type = 'DESIGNER';
      break;
    default:
      break;
  }
  return layout[type];
}
export default getLayout;
