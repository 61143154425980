<!--
 * @Author: LiuXin
 * @Date: 2021-09-08 15:41:46
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-21 14:02:46
-->
<template>
  <div class="w100 h100">
    <!-- 模块顶部 -->
    <p class="module_title">
      <span>
        <span class="title_content"> 实时概览</span>
        <a @click="gotoDetailPage">最新统计时间：{{ latestTime }}</a>
      </span>
    </p>
    <a-spin :spinning="spinLoading">
      <div class="over_view w100 h100">
        <div class="today_consume over_view_item h100">
          <div class="chart_wrapper">
            <a-progress v-bind="progressProps" :percent="progressPercent" status="active" />
          </div>
          <div class="text_description">
            <p class="text1">今日消耗</p>
            <p style="margin: 18px 0">
              <span class="text2"> {{ withComma(consumeData.cost) }}</span>
            </p>
            <p class="text3">
              <span>昨日数据消耗</span>
              <span style="margin: 0 5px"> {{ withComma(consumeData.yestDayCost) || '-' }}</span>
              <span class="status_icon">
                <CaretUpFilled v-if="consumeData.cost > consumeData.yestDayCost" />
                <CaretDownFilled v-else />
              </span>
            </p>
          </div>
        </div>
        <div class="consume_rate over_view_item h100">
          <div class="chart_wrapper">
            <home-chart v-if="consumeData.minDate" :chart-option="chartOption"></home-chart>
          </div>
          <div class="text_description">
            <p class="text1">总余额/日均消耗比</p>
            <p style="margin: 18px 0">
              <span class="text2">{{ withComma(blanceConsumeRate) || '-' }}</span>
              <span class="text3">={{ withComma(consumeData.balance) || '-' }} / {{ withComma(consumeData.avg3Cost) || '-' }}</span>
            </p>
          </div>
        </div>
        <div class="target_progress over_view_item h100" v-bind="$attrs" ref="targetProgress">
          <div class="text_description">
            <p class="text1">月度消耗</p>
            <p style="margin: 18px 0">
              <span class="text2"> {{ monthTargetData.monthCost }}</span>
            </p>
            <p class="text3">
              <span>较上月</span>
              <span style="margin: 0 5px 0 15px"> {{ monthTargetData.monthCostChangeRate }}</span>
              <span class="status_icon">
                <CaretUpFilled v-if="monthTargetData.monthCostChangeRate > 0" />
                <CaretDownFilled v-else />
              </span>
            </p>
          </div>
          <div class="target_item">
            <a-progress v-bind="progressProps" :percent="targetCompletePercent">
              <template #format="percent">
                <div class="percent_text1">{{ percent }}%</div>
                <div class="percent_text2">完成度</div>
              </template>
            </a-progress>
            <div class="text_description" style="padding-top: 15px">
              <p class="text1">
                <span class="mr5"> 月度目标 </span>
                <span class="blue-icon pointer" @click="openMonthTarget" v-hasPermission="`HOMEPAGE_MONTH_TARGET_EDIT`"><EditFilled /></span>
              </p>
              <p class="text4" style="margin: 10px 0">{{ monthTargetData.costTarget }}</p>
            </div>
          </div>
          <div class="target_item">
            <a-progress v-bind="progressProps" :percent="monthTargetData.percent">
              <template #format>
                <div class="percent_text1">{{ monthTargetData.remainDays }}天</div>
                <div class="percent_text2">剩余数</div>
              </template>
            </a-progress>
            <div class="text_description" style="padding-top: 15px">
              <p class="text1">剩余每天需完成</p>
              <p class="text4" style="margin: 10px 0">{{ monthTargetData.everydayTodo }}</p>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
  <month-target-modal v-model:visible="visible" @after-save="getData"></month-target-modal>
</template>

<script>
import { getNowConsume, getMonthConsume } from '@/api/dashboard';

import HomeChart from '../../HomeChart.vue';
import { CaretUpFilled, CaretDownFilled, EditFilled } from '@ant-design/icons-vue';
import MonthTargetModal from './MonthTargetModal.vue';

import moment from 'moment';
export default {
  name: 'OperatorOverView',
  props: { echartsOptions: { type: Object, default: () => {} } },
  components: { HomeChart, CaretUpFilled, CaretDownFilled, MonthTargetModal, EditFilled },
  data() {
    return {
      latestTime: '',
      spinLoading: true,
      consumeData: {},
      monthTargetData: {},
      progressProps: { type: 'circle', width: 80, strokeWidth: 8 },
      visible: false,
    };
  },
  computed: {
    // 消耗比图表配置
    chartOption() {
      const option = this.echartsOptions;
      option.series[0].data = [
        { value: this.consumeData.avg3Cost, name: '日均消耗' },
        { value: this.consumeData.balance, name: '总余额' },
      ];
      return option;
    },
    // 今日消耗进度百分比
    progressPercent() {
      const num = (this.consumeData.cost / this.consumeData.yestDayCost) * 100 || 0;
      return Number(num.toFixed(2));
    },
    // 总余额/日均消耗比
    blanceConsumeRate() {
      const num = this.consumeData.balance / this.consumeData.avg3Cost;
      return isNaN(num) ? '-' : Number(num.toFixed(2));
    },
    // 数字用逗号隔开
    withComma() {
      return num => (isNaN(parseFloat(num)) ? '-' : parseFloat(num).toLocaleString());
    },
    // 月目标完成比
    targetCompletePercent() {
      return Number((this.monthTargetData.costTargetCompleteRate * 100).toFixed(2)) || 0;
    },
  },
  async mounted() {
    await this.getData();
    this.getChartData();
  },
  methods: {
    // 获取今日消耗
    async getChartData() {
      this.spinLoading = true;
      const param = { date: moment().format('YYYYMMDD') };
      const res = await getNowConsume(param);
      this.consumeData = res.data;
      this.latestTime = res.data.minDate;
      this.spinLoading = false;
    },
    // 打开月度目标编辑弹框
    openMonthTarget() {
      this.visible = true;
    },
    // 获取月目标消耗数据
    async getData() {
      const res = await getMonthConsume();
      this.monthTargetData = res.data;
      this.monthTargetData.monthCost = Number((res.data.monthCost * 10000).toFixed(2)).toLocaleString();
      this.monthTargetData.costTarget = Number((res.data.costTarget * 10000).toFixed(2)).toLocaleString();
      this.monthTargetData.everydayTodo = Number((res.data.remainingCostInfo.split('*')[0] * 10000).toFixed(2));
      this.monthTargetData.remainDays = Number(parseFloat(res.data.remainingCostInfo.split('*')[1]));
      this.monthTargetData.percent = (1 - this.monthTargetData.remainDays / moment().daysInMonth()) * 100;
    },
  },
};
</script>

<style scoped lang="less">
.today_consume {
  width: 25%;
  display: flex;
  align-items: center;
  :deep(.ant-progress) {
    margin: 15px;
    .ant-progress-text {
      font-size: 14px;
      font-weight: 500;
      color: #007eff;
    }
  }
}
.consume_rate {
  width: 25%;
}
.target_progress {
  width: 50%;
  display: flex;
  align-items: center;
  .text_description {
    padding-left: 20px;
  }
  .target_item {
    flex-grow: 1;
    display: flex;
    :deep(.ant-progress-text) {
      .percent_text1 {
        font-size: 14px;
        font-weight: 500;
        color: #007eff;
        margin-bottom: 5px;
      }
      .percent_text2 {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
.chart_wrapper {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  margin-top: -5px;
}
</style>
