<!--
 * @Author: LiuXin
 * @Date: 2021-09-08 15:41:46
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-21 14:07:47
-->
<template>
  <div class="consume_trend_wrapper w100 ">
    <!-- chart图容器 -->
    <div v-for="c in children" :key="c" class="w100 home_card">
      <component :is="c.component" v-bind="c"></component>
    </div>
  </div>
</template>

<script>
import HomeChartWithTitle from './HomeChartWithTitle.vue';
export default {
  name: 'HomeChartWrapper',
  props: {
    children: {
      type: Array,
      default: () => [],
    },
  },
  components: { HomeChartWithTitle },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="less">
.consume_trend_wrapper {
  display: flex;
  & > * {
    flex: 1;
    background-color: white;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
