/*
 * @Author: LiuXin
 * @Date: 2021-09-07 18:00:42
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-21 16:59:19
 */
export default {
  // 产品消耗
  product: [
    { dataIndex: 'product_name', title: '产品名称', width: 100, ellipsis: true },
    { dataIndex: 'cost', title: '消耗', sorter: true, sortDirections: ['descend', 'ascend'] },
    { dataIndex: 'increase', title: '涨幅' },
    { dataIndex: 'chainRatio', title: '环比' },
    { dataIndex: 'accounts', title: '消耗账户数', sorter: true, sortDirections: ['descend', 'ascend'] },
    { dataIndex: 'ad_create_count', title: '新建计划数' },
    { dataIndex: 'm_cost', title: '月累计消耗', sorter: true, sortDirections: ['descend', 'ascend'] },
  ],
  // 运营消耗
  user: [
    { dataIndex: 'operator_name', title: '运营名称', width: 100, ellipsis: true },
    { dataIndex: 'cost', title: '消耗', sorter: true, sortDirections: ['descend', 'ascend'] },
    { dataIndex: 'increase', title: '涨幅' },
    { dataIndex: 'chainRatio', title: '环比' },
    { dataIndex: 'accounts', title: '消耗账户数', sorter: true, sortDirections: ['descend', 'ascend'] },
    { dataIndex: 'ad_create_count', title: '新建计划数' },
    { dataIndex: 'm_cost', title: '月累计消耗', sorter: true, sortDirections: ['descend', 'ascend'] },
  ],
  // 账户消耗
  account: [
    { dataIndex: 'advertiser', title: '账号', width: 100, ellipsis: true },
    { dataIndex: 'cost', title: '消耗', sorter: true, sortDirections: ['descend', 'ascend'] },
    { dataIndex: 'increase', title: '涨幅' },
    { dataIndex: 'chainRatio', title: '环比' },
    { dataIndex: 'ad_create_count', title: '新建计划数' },
    { dataIndex: 'm_cost', title: '月累计消耗', sorter: true, sortDirections: ['descend', 'ascend'] },
  ],
  // 设计消耗
  designerConsume: [
    { dataIndex: 'cost', title: '消耗', slots: { customRender: 'cost' } },
    { dataIndex: 'uploadNum', title: '上传数', slots: { customRender: 'uploadNum' } },
    { dataIndex: 'costMaterialNum', title: '消耗素材数', slots: { customRender: 'costMaterialNum' } },
    { dataIndex: 'costGt1000MaterialNum', title: '消耗>=1000素材', slots: { customRender: 'costGt1000MaterialNum' } },
    { dataIndex: 'costGt1000MaterialRate', title: '消耗>=1000素材率', slots: { customRender: 'costGt1000MaterialRate' } },
    { dataIndex: 'clickRate', title: '点击率', slots: { customRender: 'clickRate' } },
  ],
  // 设计人员 昨日脚本
  yesterdayScript: [
    { dataIndex: 'date', title: '时间' },
    { dataIndex: 'deptName', title: '事业部' },
    { dataIndex: 'productName', title: '产品名称' },
    { dataIndex: 'makeName', title: '脚本名称' },
    { dataIndex: 'actorsInfo', title: '参演艺人', align: 'center', slots: { customRender: 'actorsInfo' } },
    { dataIndex: 'cost', title: '成本(元/条)', align: 'center', slots: { customRender: 'cost' } },
    { dataIndex: 'shootingName', title: '摄像' },
    { dataIndex: 'screenwriterName', title: '编导' },
    { dataIndex: 'makeType', title: '脚本类型' },
  ],
};
